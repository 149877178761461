function PageLoader() {
  return (
    <div className="flex justify-center items-center h-screen flex-col gap-3">
      <div className="animate-spin rounded-full h-24 w-24 border-b-4 border-[#FFCE21]"></div>
      <p className="text-center">Loading...</p>
    </div>
  );
}

export default PageLoader;
