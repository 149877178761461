import { lazy } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
const Home = lazy(() => import("./pages/home"));
const Pay = lazy(() => import("./pages/pay"));

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/pay/:transactionRef" element={<Pay />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
